<template>
	<div class="footer-wrap">
		<div class="ehome-foot-box-bg">
			<b-row class="ehome-foot-box">
				<b-col sm="12" md="4" lg="4" xl="4" class="ehome-foot-box-item">
					<div class="ehome-foot-first">
						<img class="ehome-foot-logo" :src="footLogo" />
						<div class="ehome-foot-link-us">{{ $t("linkUs") }}</div>
						<div class="ehome-foot-link-box">
							<a :href="'tel:' + linkPhone" class="ehome-foot-link-phone">{{ linkPhone }}</a>
							<span class="ehome-foot-link-time">{{ linkTime }}</span>
						</div>
					</div>
				</b-col>
				<b-col sm="4" md="2" lg="2" xl="1" class="ehome-foot-box-item">
					<div class="ehome-foot-show-link-box">
						<div class="ehome-foot-title">
							{{ $t("footServiceCenter") }}
						</div>
						<div class="ehome-foot-line"></div>
						<div class="ehome-foot-subtitle-link" @click="dumpLink(item)"
							v-for="(item, index) in serviceCenterList" v-bind:key="index">
							{{ item.title }}
						</div>
					</div>

				</b-col>
				<b-col sm="4" md="2" lg="2" xl="1" class="ehome-foot-box-item">
					<div class="ehome-foot-show-link-box">
						<div class="ehome-foot-title">
							{{ $t("footProductCenter") }}
						</div>
						<div class="ehome-foot-line"></div>
						<div class="ehome-foot-subtitle-link" @click="dumpLink(item)"
							v-for="(item, index) in productCenterList" v-bind:key="index">
							{{ item.title }}
						</div>
					</div>

				</b-col>
				<b-col sm="4" md="2" lg="2" xl="1" class="ehome-foot-box-item">
					<div class="ehome-foot-show-link-box">
						<div class="ehome-foot-title">
							{{ $t("footCommonProblem") }}
						</div>
						<div class="ehome-foot-line"></div>
						<div class="ehome-foot-subtitle-link" @click="dumpLink(item)"
							v-for="(item, index) in commonProblemList" v-bind:key="index">
							{{ item.title }}
						</div>
					</div>

				</b-col>
				<b-col sm="4" md="2" lg="2" xl="1" class="ehome-foot-box-item">
					<div class="ehome-foot-show-link-box">
						<div class="ehome-foot-title">
							{{ $t("footAboutUs") }}
						</div>
						<div class="ehome-foot-line"></div>
						<div class="ehome-foot-subtitle-link" @click="dumpLink(item)"
							v-for="(item, index) in footAboutUsList" v-bind:key="index">
							{{ item.title }}
						</div>
					</div>

				</b-col>
				<b-col sm="12" md="12" lg="4" xl="4" class="ehome-foot-box-item">
					<!-- 了解更多 -->
					<div class="ehome-foot-show-link-box">
						<div class="ehome-foot-title">
							{{ $t("footKnowMore") }}
						</div>
						<div class="ehome-foot-img-box">
							<div class="ehome-foot-img">
								<img class="ehome-foot-min-app-img" :src="minAppImg" />
								<div class="ehome-foot-img-title">{{ $t("ehomeFootMinApp") }}</div>
							</div>
							<div class="ehome-foot-img">
								<img class="ehome-foot-min-app-img" :src="publicImg" />
								<div class="ehome-foot-img-title">{{ $t("ehomeFootPublic") }}</div>
							</div>
						</div>

					</div>
				</b-col>

			</b-row>
		</div>
		<div class="copyright-info">
			<b-container class="b-container">
				<b-row>
					<b-col sm="12" md="6" lg="3" xl="3">
						<div class="copyright">Copyright © 2022 ALL Rights Reserved</div>
					</b-col>
					<b-col sm="12" md="6" lg="3" xl="3">
						<div class="enterprise">哈尔滨胜捷房地产有限责任公司海南分公司</div>
					</b-col>
					<!-- <b-col sm="12" md="6" lg="3" xl="3">
            <div style="width:300px;margin:0 auto;">
              <a class="gaba" target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030402005136"
                style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
                <img :src="require('@/../public/icon/gaba.png')" style="float:left;" />
                <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#868281;">粤公网安备 44030402005136号</p>
              </a>
            </div>
          </b-col> -->
					<b-col sm="12" md="6" lg="3" xl="3">
						<div class="record-no"><span @click="toICP">{{ recordNo }}</span></div>
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<div class="bt-items">
							<div class="img-item" v-for="(item, index) in btItems" :key="index">
								<img :src="item" alt="">
							</div>
						</div>
					</b-col>
				</b-row>
			</b-container>
		</div>
	</div>
</template>

<script>
import dump from "../util/dump.js"
export default {
	data() {
		return {
			//底部logo
			footLogo: "img/a44.4381918b.png",
			linkPhone: "186-8995-5205",
			linkTime: "（9:00~20:00）",
			//小程序二维码
			minAppImg: "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/7152ec8c-41a7-4d60-bad2-b773077b53c5.jpg",
			//公众号二维码
			publicImg: "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/b541d3df-b18d-46e9-b97a-a2b6ba71427d.png",
			recordNo: "琼ICP备2021003823号-1",
			serviceCenterList: [{
				"title": "会员注册",
				"link": "/helpCenter",
				"params": {
					id: 1
				},
				type: 1
			},
			{
				"title": "会员权益",
				"link": "/helpCenter",
				"params": {
					id: 2
				},
				type: 1
			},
			{
				"title": "家具保养",
				"link": "/helpCenter",
				"params": {
					id: 3
				},
				type: 1
			},
			{
				"title": "常见问题",
				"link": "/helpCenter",
				"params": {
					id: 4
				},
				type: 1
			}
			],
			productCenterList: [
				{
					"title": "退换货政策",
					"link": "/helpCenter",
					"params": {
						id: 5
					},
					type: 1
				},
				{
					"title": "投诉建议",
					"link": "/helpCenter",
					"params": {
						id: 6
					},
					type: 1
				}, {
					"title": "物流配件",
					"link": "/helpCenter",
					"params": {
						id: 7
					},
					type: 1
				},
				{
					"title": "维修补件",
					"link": "/helpCenter",
					"params": {
						id: 8
					},
					type: 1
				}
			],
			commonProblemList: [
				{
					"title": "购物流程",
					"link": "/helpCenter",
					"params": {
						id: 9
					},
					type: 1
				},
				{
					"title": "收货指南",
					"link": "/helpCenter",
					"params": {
						id: 10
					},
					type: 1
				},
				{
					"title": "如何支付",
					"link": "/helpCenter",
					"params": {
						id: 11
					},
					type: 1
				},
				{
					"title": "关于发票",
					"link": "/helpCenter",
					"params": {
						id: 12
					},
					type: 1
				},
			],
			footAboutUsList: [
				{
					"title": "关于胜捷美家",
					"link": "/helpCenter",
					"params": {
						id: 13
					},
					type: 1
				},
				{
					"title": "招商合作",
					"link": "/helpCenter",
					"params": {
						id: 14
					},
					type: 1
				},
				{
					"title": "媒体报道",
					"link": "/helpCenter",
					"params": {
						id: 15
					},
					type: 1
				},
				{
					"title": "联系我们",
					"link": "/helpCenter",
					"params": {
						id: 16
					},
					type: 1
				},
			]
		}
	},
	methods: {
		dumpLink(item) {
			dump.link(item);
		},
		// 跳转到备案网站
		toICP() {
			dump.link({
				type: 2,
				link: "https://beian.miit.gov.cn/#/Integrated/index"
			})
		}
	}
}

	
</script>

<style scoped="scoped" lang="scss">
@import "../assets/scss/style.scss";
@import "../assets/font/iconfont.css";

.ehome-foot-box-bg {
	width: 100%;
	background: #1E1E1E;
	padding: 2.6875rem 0;

	.row {
		display: flex;
		flex-wrap: wrap;
		margin-right: 0;
		margin-left: 0;
	}

	.ehome-foot-box {
		.ehome-foot-box-item {
			display: flex;

			.ehome-foot-first {
				margin: auto;
				text-align: left;

				.ehome-foot-logo {
					width: 9.375rem;
					height: auto;
				}

				.icon-meiyidalogo {
					font-size: 58px;
					color: #fff;
				}

				.ehome-foot-link-us {
					font-size: 1.0625rem;
					font-family: Source Han Sans SC;
					font-weight: 400;
					color: $font-default-foot-link-color;
					padding-top: 5.3125rem;
				}

				.ehome-foot-link-box {
					.ehome-foot-link-phone {
						font-size: 1.8125rem;
						font-family: Brandon Text;
						font-weight: bold;
						color: #FFFFFF;
					}

					.ehome-foot-link-time {
						font-size: 1.0625rem;
						font-family: Source Han Sans SC;
						font-weight: 400;
						color: $font-default-foot-link-color;
						padding-left: 17px;
					}
				}
			}

			.ehome-foot-show-link-box {
				text-align: left;

				.ehome-foot-title {
					font-size: 17px;
					font-family: Source Han Sans SC;
					font-weight: 400;
					color: #FFFFFF;
				}

				.ehome-foot-line {
					width: 3.4375rem;
					height: 0.125rem;
					background: $font-default-foot-link-color;
					margin-top: 0.6875rem;
					margin-bottom: 2.0625rem;
				}

				.ehome-foot-subtitle-link {
					font-size: 0.9375rem;
					font-family: Source Han Sans SC;
					font-weight: 400;
					color: $font-default-foot-link-color;
					cursor: pointer;
					padding-bottom: 0.875rem;
				}

				.ehome-foot-img-box {
					display: flex;

					.ehome-foot-img {
						margin-top: 1.4375rem;
						margin-right: 2.625rem;

						.ehome-foot-min-app-img {
							width: 7.875rem;
							height: 7.875rem;

						}

						.ehome-foot-public-img {
							width: 7.875rem;
							height: 7.875rem;
						}

						.ehome-foot-img-title {
							font-size: 1.0625rem;
							font-family: Source Han Sans SC;
							font-weight: 400;
							color: $font-default-foot-link-color;
							text-align: center;
							padding-top: 1.1875rem;
						}
					}
				}

			}

		}
	}


}

.copyright-info {
	background-color: #342c2a;

	.b-container {
		padding-top: 30px;
		padding-bottom: 26px;
		font-size: 14px;
		color: #868281;

		.gaba {
			&:hover {
				p {
					color: #D19D7E !important;
				}
			}
		}

		.record-no {
			span {
				cursor: pointer;

				&:hover {
					color: #D19D7E;
				}
			}
		}

		.bt-items {
			display: flex;
			justify-content: center;
			align-items: center;
			padding-top: 30px;

			.img-item {
				padding: 0 20px;
			}
		}
	}
}
</style>