<template>
	<div class="ehome-right-box">
		<!-- <div class="ehome-right-item-box">
			<img class="ehome-right-icon" src="../../public/icon/right/sign@2x.png" />
			<div class="ehome-right-title">签到</div>
		</div> -->
		<div class="ehome-right-item-box" @click="customerStart()">
			<img class="ehome-right-icon" src="../../public/icon/right/customer@2x.png" />
			<div class="ehome-right-title">客服</div>
		</div>
		<!-- <div class="ehome-right-item-box">
			<img class="ehome-right-icon" src="../../public/icon/right/edit@2x.png" />
			<div class="ehome-right-title">反馈</div>
		</div> -->
		<div class="ehome-right-item-box" @click="backTop">
			<img class="ehome-right-icon" src="../../public/icon/right/top@2x.png" />
			<div class="ehome-right-title">顶部</div>
		</div>
	</div>
</template>

<script>
	import api from "../api/api.js"
	export default {
		data() {
			return {
				scrollTop: 0
			}
		},
		methods: {
			customerStart() {
				api.banners({}).then(function() {
					setTimeout(function() {
						window.open('http://s.appurls.cn/s/46610otgd', 'newwindow',
							'height=400, width=400, top=0, left=0, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=n o, status=no'
						)
					}, 100);
				}).catch(function() {
					setTimeout(function() {
						window.open('http://s.appurls.cn/s/46610otgd', 'newwindow',
							'height=400, width=400, top=0, left=0, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=n o, status=no'
						)
					}, 100);
				})

			},
			backTop() {
				document.documentElement.scrollTop =0;
			},
		}

	}
</script>

<style scoped="scoped" lang="scss">
	@import "../assets/scss/style.scss";

	.ehome-right-box {
		cursor: pointer;
		position: fixed;
		right: 0.9375rem;
		bottom: 20%;
		width: 3.125rem;
		background: #FFFFFF;
		box-shadow: 0px 0.125rem 0.625rem 0px rgba(0, 0, 0, 0.2);
		border-radius: 0.3125rem;
		padding: 0.625rem 0;
		text-align: center;
		z-index: 9999;

		.ehome-right-item-box:not(:first-child) {
			padding-top: 0.625rem;
		}

		.ehome-right-item-box {
			.ehome-right-icon {
				// width: 1.5rem;
				height: 1.25rem;
			}

			.ehome-right-title {
				padding-top: 0.125rem;
				font-size: 0.8125rem;
				font-family: Source Han Sans SC;
				font-weight: 400;
				color: #221815;
			}
		}
	}
</style>
